

















































import Vue from 'vue';
import { Table, TableColumn, Pagination } from 'element-ui';
import { GET_USER_POINTS_QUERY } from '../graphqls/user';
import Instant from '@/components/Labels/Instant.vue';

enum PointKind {
  SAVED = 'SAVED',
  USED = 'USED',
  EXPIRED = 'EXPIRED',
}
enum PointAccountingType {
  PROMOTION = 'PROMOTION',
  WELFARE = 'WELFARE',
  BUSINESS = 'BUSINESS',
  NONE = 'NONE',
}
export default Vue.extend({
  components: {
    Instant,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Pagination.name]: Pagination,
  },
  props: {
    userId: String,
  },
  data(): {
    page: number;
    size: number;
    totalCount: number;
    currentPage: number;
  } {
    return {
      page: 1,
      size: 10,
      totalCount: 1,
      currentPage: 1,
    };
  },
  apollo: {
    points: {
      query: GET_USER_POINTS_QUERY,
      variables(): { id: string; page: number; size: number } {
        return {
          id: this.userId,
          page: this.currentPage,
          size: this.size,
        };
      },
      update(data) {
        this.totalCount = data.user.points.totalCount;
        return data.user.points.edges.map((edge: any) => edge.node);
      },
    },
  },
  methods: {
    convertPointKind(kind: PointKind, cancelled: boolean): string {
      switch (kind) {
        case PointKind.SAVED:
          return cancelled ? '지급 취소' : '지급';
        case PointKind.USED:
          return cancelled ? '사용 취소' : '사용';
        case PointKind.EXPIRED:
          return '만료';
        default:
          return '';
      }
    },
    convertPointAccountingType(type: PointAccountingType): string {
      switch (type) {
        case PointAccountingType.PROMOTION:
          return '광고선전비';
        case PointAccountingType.WELFARE:
          return '복리후생비';
        case PointAccountingType.BUSINESS:
          return '그 외';
        case PointAccountingType.NONE:
          return '해당없음';
        default:
          return '';
      }
    },
  },
});
