


























































































































import Vue from 'vue'; // eslint-disable-line no-unused-vars
import {
  Table,
  TableColumn,
  Pagination,
  DropdownMenu,
  DropdownItem,
  Dropdown,
} from 'element-ui';
import Spinner from '@/components/Spinner.vue';
import ContentCard from '@/components/FripComponents/ContentCard.vue';
import { User, UserFilter } from '../models/user';
import { GET_USER_LIST_BY_FILTER_QUERY } from '../graphqls/user';
import SocialLink from '@/components/SocialLink.vue';
export default Vue.extend({
  name: 'HostList',
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Pagination.name]: Pagination,
    ContentCard,
    SocialLink,
    Spinner,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
  },
  data() {
    return {
      searchWordTypeOptions: [
        { value: 'majorLike', text: '전체' },
        { value: 'mobileNumberLike', text: '연락처' },
        { value: 'id', text: 'ID' },
        { value: 'emailLike', text: '이메일' },
        { value: 'nicknameLike', text: '닉네임' },
      ],
      searchFilter: {
        type: 'majorLike',
        value: '',
      },
      filter: {
        id: '',
        emailLike: '',
        nicknameLike: '',
        mobileNumberLike: '',
      } as UserFilter,
      users: [],
      totalCount: 1,
      totalPage: 1,
      currentPage: 1,
      size: 20,
    };
  },
  apollo: {
    users: {
      query: GET_USER_LIST_BY_FILTER_QUERY,
      variables(): { filter: UserFilter; page: number; size: number } {
        console.log(this.$data.currentPage);
        console.log(this.filter);
        return {
          filter: this.filter,
          page: this.$data.currentPage,
          size: this.size,
        };
      },
      // update:(date)=> 와의 차이점은 .. ?
      update(data) {
        console.log(data);
        this.$data.totalPage = Math.ceil(
          data.users.totalCount / this.$data.size
        );

        console.log(this.$data.totalPage);

        this.$data.totalCount = data.users.totalCount;
        return data.users.edges.map((edge: { node: User }, index: number) => ({
          ...edge.node,
          index,
        }));
      },
    },
  },

  methods: {
    search() {
      const type: string = this.$data.searchFilter.type;
      const value = this.$data.searchFilter.value;
      const filter: {
        [key: string]: string;
      } = {};
      filter[type] = value;
      console.log(filter);
      this.$data.filter = filter;
    },
    reset() {
      this.$set(this.$data.searchFilter, 'value', '');
    },
    registedAt(user: User): string {
      return this.$moment(user.joinedAt).format('YYYY-MM-DD HH:mm:ss');
    },
    setPage(val: number) {
      this.$data.currentPage = val;
    },
  },
});
