

































































































































































































import InlineInput from '@/components/Forms/InlineInput.vue';
import InlineRadioInput from '@/components/Forms/InlineRadioInput.vue';
import InlineText from '@/components/Forms/InlineText.vue';
import Vue from 'vue';
import {
  CERTIFICATE_USER,
  CHANGE_MOBILE_NUMBER,
  DECERTIFICATE_USER,
  GET_ONE_QUERY,
  UNVERIFY_USER_MUTATION,
  UPDATE_MUTAION,
  VERIFY_USER_MUTATION,
  WITHDRAW_MUTATION,
} from '../graphqls/user';
import Instant from '@/components/Labels/Instant.vue';
import { Pagination, Select, Table, TableColumn } from 'element-ui';
import { User } from '../models/user';
import SocialLink from '@/components/SocialLink.vue';
import InputRow from '@/components/Forms/InputRow.vue';
import UserPointHistoryList from './UserPointHistoryList.vue';

export default Vue.extend({
  components: {
    InlineInput,
    InlineRadioInput,
    InlineText,
    Instant,
    SocialLink,
    InputRow,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Pagination.name]: Pagination,
    UserPointHistoryList,
    [Select.name]: Select,
  },
  data(): { user: User; originUser: User | null } {
    return {
      user: {
        birth: '',
        socialLinks: [],
        id: '',
        email: '',
        mobileNumber: '',
        description: '',
        gender: '',
        nickname: '',
        noticeReceiveAgreements: [],
        privateProfile: false,
        certificated: false,
        verified: false,
        usablePoint: {
          total: 0,
        },
        tags: [],
      },
      originUser: null,
    };
  },
  computed: {
    converted: {
      get(): string {
        let result = '';
        if (!this.user.birth) {
          return result;
        }
        result += this.user.birth.substring(0, 4);
        result += '-';
        result += this.user.birth.substring(4, 6);
        result += '-';
        result += this.user.birth.substring(6, 8);
        return result;
      },
      set(yyyyMMdd: string): void {
        let result = '';
        if (yyyyMMdd) {
          result += yyyyMMdd.substring(0, 4);
          result += yyyyMMdd.substring(5, 7);
          result += yyyyMMdd.substring(8, 10);
          this.user.birth = result;
        }
      },
    },
  },
  apollo: {
    user: {
      query: GET_ONE_QUERY,
      variables(): { id: string } {
        return {
          id: this.$route.params.id,
        };
      },
      update(data): User {
        this.originUser =
          this.originUser || JSON.parse(JSON.stringify(data.user));
        console.log('data.user :', data.user);
        return data.user;
      },
    },
  },
  methods: {
    deleteModal(user: User) {
      const deleteUser = async () => {
        try {
          const res = await this.$apollo.mutate({
            mutation: WITHDRAW_MUTATION,
            variables: {
              userId: user.id,
            },
          });
          this.$modal.show({
            title: '삭제 성공!',
            type: 'info',
            showCancelButton: true,
            //   message: err.message,          showCancelButton: true,
          });
        } catch (err: unknown) {
          this.$modal.show({
            title: '삭제 실패!',
            type: 'warning',
            message: (err as Error).message,
            showCancelButton: true,
          });

          throw err;
        }
        return user;
      };
      this.$modal.show(
        {
          title: '정말 삭제하시겠습니까?',
          type: 'warning',
          showCancelButton: true,
        },
        deleteUser
      );
    },
    changeMobileNumberModal(user: User) {
      const changeMobileNumber = async () => {
        try {
          const res1 = await this.$apollo.mutate({
            mutation: CHANGE_MOBILE_NUMBER,
            variables: {
              mobileNumber: user.mobileNumber,
              userId: user.id,
            },
          });
          this.$modal.show({
            title: '수정 성공!',
            type: 'info',
            //   message: err.message,
            showCancelButton: true,
          });
        } catch (err: unknown) {
          this.$modal.show({
            title: '수정 실패!',
            type: 'warning',
            message: (err as Error).message,
            showCancelButton: true,
          });

          throw err;
        }
        return user;
      };
      this.$modal.show(
        {
          title: '정말 수정하시겠습니까?',
          type: 'warning',
          showCancelButton: true,
        },
        changeMobileNumber
      );
    },
    toggleVerifyPhoneNumber(user: User) {
      const userVerifyMutation = async () => {
        try {
          await this.$apollo.mutate({
            mutation: VERIFY_USER_MUTATION,
            variables: {
              userId: user.id,
            },
          });
        } catch (err) {
          this.$modal.show({
            title: '수정 실패!',
            type: 'warning',
            message: (err as Error).message,
            showCancelButton: true,
          });

          user.verified = !user.verified;
          throw err;
        }
      };

      const userUnverifyMutation = async () => {
        try {
          await this.$apollo.mutate({
            mutation: UNVERIFY_USER_MUTATION,
            variables: {
              userId: user.id,
            },
          });
        } catch (err) {
          this.$modal.show({
            title: '수정 실패!',
            type: 'warning',
            message: (err as Error).message,
            showCancelButton: true,
          });

          user.verified = !user.verified;
          throw err;
        }
      };

      if (user.verified) {
        userVerifyMutation();
      } else {
        this.$modal.show(
          {
            title: '정말 본인인증을 취소하시겠습니까??',
            type: 'warning',
            showCancelButton: true,
          },
          userUnverifyMutation
        );
      }
    },
    updateModal(user: User) {
      const param = {
        email: user.email,
        nickname: user.nickname,
        description: user.description,
        birth: user.birth,
        gender: user.gender,
      };
      const updateUser = async () => {
        try {
          const res = await this.$apollo.mutate({
            mutation: UPDATE_MUTAION,
            variables: {
              param,
              userId: user.id,
            },
          });
          if (this.originUser?.certificated !== user.certificated) {
            if (user.certificated) {
              const res = await this.$apollo.mutate({
                mutation: CERTIFICATE_USER,
                variables: {
                  userId: user.id,
                },
              });
            } else {
              const res = await this.$apollo.mutate({
                mutation: DECERTIFICATE_USER,
                variables: {
                  userId: user.id,
                },
              });
            }
          }
          this.$modal.show(
            {
              title: '수정 성공!',
              type: 'info',
              //   message: err.message,
            },
            () => {
              location.reload();
            }
          );
        } catch (err: unknown) {
          this.$modal.show({
            title: '수정 실패!',
            type: 'warning',
            message: (err as Error).message,
            showCancelButton: true,
          });

          throw err;
        }
        return user;
      };
      this.$modal.show(
        {
          title: '정말 수정하시겠습니까?',
          type: 'warning',
          showCancelButton: true,
        },
        updateUser
      );
    },
  },
});
