




















import Vue from 'vue';
import { SocialLinkProviderType } from '../domain/user/models/user';
export default Vue.extend({
  props: {
    provider: {
      type: String,
    },
  },
  data() {
    return {
      socialType: '',
      width: 20,
    };
  },
  mounted() {
    this.convertSocialLink(this.$props.provider);
  },
  methods: {
    convertSocialLink(type: SocialLinkProviderType) {
      console.log(type);
      switch (type) {
        case SocialLinkProviderType.KAKAO_SYNC:
          this.socialType = '카카오';
          break;

        case SocialLinkProviderType.FACEBOOK:
          this.socialType = '페이스북';

          break;
        case SocialLinkProviderType.APPLE:
          this.socialType = '애플';
          break;
        default:
          this.socialType = '이메일';
          break;
      }
    },
  },
});
