var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('el-table',{staticClass:"table table-responsive mt-5",attrs:{"header-row-class-name":"thead-light","cell-style":{ textAlign: 'center' },"header-cell-style":{ textAlign: 'center' },"data":_vm.points,"border":""}},[_c('el-table-column',{attrs:{"label":"에너지","prop":"amount","min-width":"80"}}),_c('el-table-column',{attrs:{"label":"부분사용에너지","prop":"usedAmount","min-width":"80"}}),_c('el-table-column',{attrs:{"label":"지급/사용/만료","prop":"kind","min-width":"60"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.convertPointKind(row.kind, row.cancelled))+" ")]}}])}),_c('el-table-column',{attrs:{"label":"내용","prop":"description","min-width":"100"}}),_c('el-table-column',{attrs:{"label":"적립일시"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('instant',{attrs:{"at":row.createdAt}})]}}])}),_c('el-table-column',{attrs:{"label":"유효기간","prop":"period","min-width":"80"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.expiredAt)?_c('instant',{attrs:{"at":row.expiredAt}}):_vm._e()]}}])}),_c('el-table-column',{attrs:{"label":"회계구분","prop":"period","min-width":"80"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.convertPointAccountingType(row.accountingType))+" ")]}}])})],1),_c('b-pagination',{staticClass:"mt-3",attrs:{"total-rows":_vm.totalCount,"per-page":_vm.size,"align":"center","first-number":"","last-number":""},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }